import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { pxToRem } from '../theme';

import CheckmarkIcon from '../images/tickcheckmark.svg';

const styles = theme =>
  createStyles({
    checkmarkListItem: {
      padding: '10px 0',
    },
    primary: {
      fontSize: pxToRem(20),
      fontWeight: 600,
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(28),
      },
      [theme.breakpoints.up(960)]: {
        paddingLeft: 10,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(32),
      }
    },
    smallerPrimary: {
      fontSize: pxToRem(16),
      fontWeight: 300,
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(20),
      },
      [theme.breakpoints.up(960)]: {
        paddingLeft: 10,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(24),
      }
    },
    img: {
      width: 30,
    },
  });

const CheckmarkLine = ({ smaller, lineText, classes }) => (
  <ListItem className={classes.checkmarkListItem}>
    <ListItemIcon>
      <img className={classes.img} alt="" src={CheckmarkIcon} />
    </ListItemIcon>
    <ListItemText
      classes={{
        primary: smaller ? classes.smallerPrimary : classes.primary,
      }}
      primary={lineText}
    />
  </ListItem>
);

export default withStyles(styles, { withTheme: true })(CheckmarkLine);
