import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import CheckmarkLine from './CheckmarkLine';

const styles = theme =>
  createStyles({
    checkmarksWrap: {
      overflowY: 'auto',
      padding: 25,
      background: theme.palette.common.white,
      [theme.breakpoints.up(350)]: {
        padding: 40,
      },
    },
  });

const CheckmarkBlock = ({ smaller, texts, classes, style = {} }) => {
  return (
    <List
      style={style}
      className={classes.checkmarksWrap}
    >
      {texts.map(text => (
        <CheckmarkLine smaller={smaller} key={text} lineText={text} />
      ))}
    </List>
  );
};

export default withStyles(styles, { withTheme: true })(CheckmarkBlock);
