import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { pxToRem } from '../theme';

const styles = theme =>
  createStyles({
    statementWrap: {
      padding: 40,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        backgroundColor: '#E9E9E9',
      },
    },
    statementTitle: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(34),

      }
    },
    statementCopy: {
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(24),
      }
    }
  });

const StatementBlock = ({
  classes,
  title,
  statementCopy,
}) => (
  <Grid
    item
    className={classes.statementWrap}
  >
      {title && <Typography
          variant="h1"
          color="primary"
          className={classes.statementTitle}
        >
          {title}
        </Typography>
      }
      {statementCopy && (
        <Typography variant="body1" color="primary"
          className={classes.statementCopy}
        >
          {statementCopy}
        </Typography>
      )}
  </Grid>
);

export default withStyles(styles, { withTheme: true })(StatementBlock);
