
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import StatementBlock from './StatementBlock';
import CenterContent from './CenterContent';
import CheckmarkBlock from './CheckmarkBlock';

const styles = theme =>
  createStyles({
    root: {
      maxWidth: 960,
      margin: '0 auto',
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up(960)]: {
        padding: '25px 0',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    padded: {
      [theme.breakpoints.up('md')]: {
        padding: 15,
      },
    },
  });

const ServiceIntroBlock = ({ smallerChecklist, checklist, statementTitle, statementCopy, classes, theme }) => {
  return (
    <Grid container item className={classes.root}>
      <CenterContent
        doStretch
      >
        <Grid container item sm={6} className={classes.padded}>
          <CheckmarkBlock
            smaller={smallerChecklist}
            texts={checklist}
          />
        </Grid>
        <Grid container item sm={6} className={classes.padded}>
          <StatementBlock
            title={statementTitle}
            statementCopy={statementCopy}
          />
        </Grid>
      </CenterContent>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ServiceIntroBlock);
