import React from 'react';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

import submitForm from '../helpers/submitForm';

import { withQuoteWizard } from '../components/withQuoteWizard';

import { LandscapersTradePage } from '../imageURLs';

import Hero from '../components/Hero/Hero';
import SEO from '../components/SEO';

import ServiceIntroBlock from '../components/ServiceIntroBlock';
import TextBlock from '../components/TextBlock';
import GreenCta from '../components/GreenCta';
import Post from '../components/Post/Post';
import Input from '../components/Quote/Input';
import { UiButton } from '../components/CTAButton/CTAButton';
import ResultSnackbar from '../components/ResultSnackbar/ResultSnackbar';

import { enlargePercent, CenterStyle } from '../theme';

const styles = theme =>
  createStyles({
    collapse: {
      backgroundColor: '#E9E9E9',
    },
    enquiryWrap: {
      ...CenterStyle,
      maxWidth: 600,
      padding: '40px 20px 60px 20px',
      textAlign: 'center',
    },
    enquiryTextWrap: {
      padding: '0 10px',
    },
    enquiryText: {
      fontSize: '1.5rem',
      lineHeight: 1,
      marginTop: 30,
      fontWeight: 300,
      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1.5)}rem`,
      },
    },
    enquiryTextWidthSale: {
      backgroundColor: 'red !important',
    },
    'greenCta.desktop': {
      display: 'none',
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    'greenCta.mobile': {
      display: 'flex',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    clientProfile: {
      [theme.breakpoints.up(960)]: {
        flexDirection: 'column',
      },
    },
    
  });

const inputStyle = {
  marginBottom: 30,
};

class LandscapersAndTrade extends React.Component {
  constructor() {
    super();

    this.state = {
      showEnquiry: false,
      formData: {},
      snack: null,
      isLoading: false
    };
  }

  componentDidMount() {
    if (/action=enquire/.test(this.props.location.search)) {
      this.showEnquiry();
    }
  }

  showEnquiry = () => {
    this.setState(
      { showEnquiry: true },
      () => {
        // Accessing the DOM directly here
        // as Material UI doesn't seem to forward refs to a DOM node.
        const companyInput = document.getElementById('companyInput');
        // Centering the view on the input so it is reliably in view
        // and the header cannot obscure the form at some viewport sizes.
        companyInput.scrollIntoView({ block: 'center', behavior: 'smooth' });
        // Delay focusing to allow the scroll animation to complete.
        setTimeout(() => companyInput.focus(), 500);
      }
    );
  };

  getInputProps = field => ({
    placeholder: field,
    value: this.state.formData[field] || '',
    onChange: event => {
      const { value } = event.target;
      this.setState(state => ({
        formData: {
          ...state.formData,
          [field]: value
        }
      }));
    },
    required: true,
    disabled: this.state.isLoading,
  });

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    submitForm('Trade pricing enquiry', this.state.formData)
      .then(() => {
        this.setState({
          snack: { variant: 'success', message: 'Successfully submitted trade pricing enquiry.' },
          formData: {},
        });
      })
      .catch(() => {
        this.setState({
          snack: { variant: 'error', message: 'Error submitting trade pricing enquiry.' },
        });
      })
      .then(() => this.setState({ isLoading: false }));
  }

  handleCloseSnack = () => this.setState({ snack: null });

  render() {
    const { classes, children } = this.props;

    const { showEnquiry } = this.state;

    return (
      <React.Fragment>
        <SEO
          title="Trade Supported by Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Grid container item direction="column">
          <Grid item>
            <Hero heroImage={LandscapersTradePage.hero} noQuote>
              <TextBlock text="Landscapers and trade" />
            </Hero>
          </Grid>

          <Grid item container style={{ backgroundColor: '#fff' }}>
            <ServiceIntroBlock
              checklist={['Easy', 'Reliable', 'Professional']}
              statementTitle="SEQ's trade turf specialists"
              statementCopy="Twin View Turf are SEQ’s most professional, reliable turf supplier with highest quality lawns delivered fresh the same day of harvest."
            />
          </Grid>
          
          <Grid item className={classes['greenCta.mobile']}>
            <GreenCta
              ctaTitle="Call us today"
              ctaHref="tel:1800686711"
              ctaText="Call now"
              isExternalLink1={true}
              ctaHref2={'/trade/?action=enquire'}
              ctaText2={'Get pricing'}
              ctaOnClick2={this.showEnquiry}
              invertedBg
              // inverted
            
            />
          </Grid>
          <Grid item className={classes['greenCta.desktop']}>
            <GreenCta
              ctaTitle="Call us today"
              ctaHref="/contact"
              ctaText="Call now"
              ctaHref2={'/trade/?action=enquire'}
              ctaText2={'Get pricing'}
              ctaOnClick2={this.showEnquiry}
              invertedBg

            />
          </Grid>

          <Collapse in={showEnquiry} timeout="auto" className={classes.collapse}>
            <Grid container direction="column" className={classes.enquiryWrap}>
              <Typography
                variant="h1"
                color="primary"
                style={{ marginBottom: 40 }}
              >
                Your details:
              </Typography>
              <form onSubmit={this.handleSubmit}>
                <Grid item container justify="center">
                  <Input
                    {...this.getInputProps('Your company')}
                    style={inputStyle}
                    id="companyInput"
                  />
                  <Input
                    {...this.getInputProps('Your name')}
                    style={inputStyle}
                  />
                  <Input
                    {...this.getInputProps('Your email')}
                    style={inputStyle}
                  />
                </Grid>
                <Grid item container justify="center">
                  <UiButton type='submit' btnType='wide' disabled={this.state.isLoading} >
                    Send enquiry
                  </UiButton>
                </Grid>
                {this.state.snack && <ResultSnackbar {...this.state.snack} onClose={this.handleCloseSnack} />}
              </form>
              <Grid
                item
                container
                justify="center"
                className={classes.enquiryTextWrap}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.enquiryText}
                >
                  We have a dedicated Trade Account Manager who is available to
                  assist with your needs. They will be in touch shortly.
                </Typography>
              </Grid>
            </Grid>
          </Collapse>

          <Grid 
            container 
            direction="column" 
            alignItems="center"
            className={classes.TextWidthTrade}
          >
            <Post
              noSpec
              noQuote
              middle
              sticky
              untightIt="true"
              quotePosition
              // makeItHappneForTrade
              customerName="Stuart, S & L Garden Makeovers"
              bodyCopy="My philosophy is quality – from my workmanship to the materials I use. Twin View Turf have the highest quality lawns and service, which is why I choose them for all of my landscaping projects."
              coverImage={LandscapersTradePage.testimonialHero}
              profileImage={LandscapersTradePage.happyCustomer}
              imageStyle={{ paddingTop: -120, margin: '0 auto' }}
            />
          </Grid>

          {/* <Grid item className={classes['greenCta.mobile']}>
            <GreenCta
              ctaTitle="Call us today"
              ctaHref="tel:1800686711"
              ctaText="Call now"
              isExternalLink1={true}
              ctaHref2={'/trade/?action=enquire'}
              ctaText2={'Get pricing'}
              ctaOnClick2={this.showEnquiry}
              invertedBg
              // inverted
            
            />
          </Grid> */}

          {children}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(LandscapersAndTrade)
);
